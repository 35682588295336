import { Component, Input, ViewChild } from "@angular/core";
import { NavController, ModalController, ToastController, AlertController, Slides, PopoverController } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { ManageProductPage } from "../../actions/manage-product/manage-product.page";
import { PopoverComponent } from "../../../components/popover/popover.component";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"]
})
export class ProductsComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() cid: any;
  @ViewChild("slides") slides: Slides;
  public selectedSegment = 'Details';
  public getAllProductData: any;
  public items: any;
  public itemsValue: any;
  public arrowKeyLocation = -1;
  public arrowKeyLocationValue = -1;
  public selectedProduct = "";
  public manageSelectedProduct = "";
  public listOfValue = [];
  public description = "";
  public productData: any;
  public id: any;
  public valueSearch = "";
  public name = "";
  public flgBtn = "";
  public arrowKeyLocationVariant = -1;
  public listOfVariants = [];
  public listOfBanners: any = [];
  public productSku = "";
  public listOfTags = [];
  public arrowKeyLocationTag = -1;
  public offset = 0;
  public limit = 10;
  public shippingWeight: any;
  public allowInventory: boolean;
  public liveStatus: boolean;
  public transformTypes = [];

  public slideOpts = {
    effect: "flip",
    // loop: true,
    slidesPerView: 3,
    spaceBetween: 5,
    zoom: true,
    scrollbar: true,
    direction: "horizontal"
  };
  constructor(private authService: AuthenticationService, private popoverController: PopoverController, public httpApiService: HttpApiService, public storage: Storage, private alertCtrl: AlertController, public toastCtrl: ToastController, public navCtrl: NavController, public modalCtrl: ModalController, public ConfigService: ConfigService) { }

  async ngOnInit() {
    await this.getTransformationTypes()
    this.getAllProducts();
  }

  setItems() {
    this.items = this.getAllProductData;
  }

  async getTransformationTypes() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getTransformationTypes(refCompanyId);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.transformTypes = res.json().data;
    }
  }

  async changeTransformationType(imgData, newValue, index, page) {
    let obj = {}
    if (page == 'detail') {
      obj["transformType"] = newValue
      this.listOfBanners[index]['transformType'] = newValue;
    }
    if (page == 'search') {
      obj["transformType_search"] = newValue
      this.listOfBanners[index]['transformType_search'] = newValue;
    }
    if (page == 'collection') {
      obj["transformType_collection"] = newValue
      this.listOfBanners[index]['transformType_collection'] = newValue;
    }
    if (page == 'slider') {
      obj["transformType_slider"] = newValue
      this.listOfBanners[index]['transformType_slider'] = newValue;
    }

    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");

    await this.httpApiService.showLoading();
    res = await this.httpApiService.changeImageTransformation(refCompanyId, imgData.id, obj);
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      console.log("error");
    } else {
      let toast = await this.toastCtrl.create({ message: "Image Transformation Type Updated successfully", duration: 2000, position: "top", color: "success" });
      await toast.present();
    }
  }

  async getAllProducts() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getAllProducts(refCompanyId);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllProductData = res.json().data;
      this.items = this.getAllProductData;
      console.log("get All product param Data", this.getAllProductData);
    }
  }

  async openImage(image) {
    window.open('https://res.cloudinary.com/dealerclub/image/upload/' + image);
  }

  changed(evt) {
    if (evt.detail.value !== this.allowInventory) {
      this.updateProduct()
    }
  }

  changeLive(evt) {
    if (evt.detail.value !== this.liveStatus) {
      this.updateProduct()
    }
  }

  async makeCoverImage(row) {
    console.log(row.id);
    console.log(row.isCoverImage)
    console.log("row", row);
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.updateProductCoverImage(row.id, refCompanyId, row.isCoverImage);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        // this.variantSearch = "";
        //await this.searchProductDetail(this.manageSelectedProduct);
        let toast = await this.toastCtrl.create({ message: "Product Cover image updated successfully", duration: 2000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }

  getProductDetail(name) {
    console.log(name);
    this.searchProductDetail(name);
  }

  async searchProductDetail(name) {
    let tmpName = "";
    this.reset();
    this.items = this.getAllProductData;
    if (name != undefined) {
      tmpName = name;

      this.manageSelectedProduct = name;
      // this.selectedProduct = name;
    } else {
      // this.selectedProduct = this.manageSelectedProduct;
      tmpName = this.manageSelectedProduct;
    }

    let selectedProductId = this.items.filter(function (search) {
      if (search.name == tmpName) {
        return search.id;
      }
    });
    console.log(this.items, tmpName, selectedProductId);
    if (selectedProductId.length > 0) {
      this.id = selectedProductId[0].id;

      let res: any;
      let refCompanyId = await this.storage.get("refCompanyId");
      await this.httpApiService.showLoading();
      res = await this.httpApiService.getProductDetailById(this.id, refCompanyId);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json().data;
        console.log("data", data);
        this.productData = data[0];
        this.allowInventory = data[0].allowInventory || false;
        this.liveStatus = data[0].liveStatus || false;
        this.name = data[0].name;
        this.description = data[0].description;
        this.productSku = data[0].sku;
        this.shippingWeight = data[0].shippingWeight;
        this.listOfBanners = data[0].listOfProductImages;
        if (data[0].listOfParameter && data[0].listOfParameter.length > 0) {
          this.listOfValue = data[0].listOfParameter;
          // this.productData = this.listOfValue;
        } else {
          this.listOfValue = [];
        }
        if (data[0].listOfVariants && data[0].listOfVariants.length > 0) {
          this.listOfVariants = data[0].listOfVariants;
        } else {
          this.listOfVariants = [];
        }
        if (data[0].listOfTags && data[0].listOfTags.length > 0) {
          this.listOfTags = data[0].listOfTags;
        } else {
          this.listOfTags = [];
        }
        let index = this.items.findIndex(x => x.name == selectedProductId[0].name);
        this.arrowKeyLocation = index;
      }
    } else {
      this.name = "";
      this.manageSelectedProduct = "";
    }
  }

  async filterItems(ev: any) {
    this.selectedProduct = "";
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      let refCompanyId = await this.storage.get("refCompanyId");
      let res = await this.httpApiService.fetchProductsByPagination(this.offset, this.limit, val, refCompanyId);
      console.log("res", res);
      if (res.status == 401) {
        this.authService.logout();
      } else {
        if (res.json().isSuccess) {
          this.getAllProductData = res.json().data;
          this.items = this.getAllProductData;
          console.log("get all products data", this.getAllProductData);
          this.arrowKeyLocation = -1;
        }
      }
    } else {
      this.arrowKeyLocation = -1;
      this.items = this.getAllProductData;
    }
  }

  next() {
    this.slides.slideNext();
  }

  prev() {
    this.slides.slidePrev();
  }

  //Start Inner Search

  setValues() {
    this.listOfValue = this.productData.listOfParameter;
  }

  async filterValues(ev: any) {
    this.setValues();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.listOfValue = await this.listOfValue.filter(function (item) {
        return item.paramName.toLowerCase().includes(val.toLowerCase());
      });
      // console.log(this.listOfValue);
      this.arrowKeyLocationValue = -1;
    } else {
      this.listOfValue = this.productData.listOfParameter;
      this.arrowKeyLocationValue = -1;
    }
  }

  keyDownValues(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.itemsValue && this.itemsValue.length > 0) {
          if (this.arrowKeyLocationValue != 0) {
            this.arrowKeyLocationValue--;
            // this.manageSelectedProduct = this.items[this.arrowKeyLocation].name;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.itemsValue && this.itemsValue.length > 0) {
          if (this.listOfValue.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocationValue++;
          }
          if (this.itemsValue.length > this.arrowKeyLocation) {
            // this.manageSelectedProduct = this.items[this.arrowKeyLocation].name;
            break;
          } else {
            break;
          }
        }
        if (this.listOfValue.length > this.arrowKeyLocationValue) {
          if (this.listOfValue.length != this.arrowKeyLocationValue + 1) {
            this.arrowKeyLocationValue++;
          }
          break;
        } else {
          break;
        }
    }
  }

  setVariants() {
    this.listOfVariants = this.productData.listOfVariants;
  }

  async filterVariant(ev: any) {
    this.setVariants();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.listOfVariants = await this.listOfVariants.filter(function (item) {
        return item.title.toLowerCase().includes(val.toLowerCase());
      });
      console.log(this.listOfVariants);
      this.arrowKeyLocationVariant = -1;
    } else {
      this.listOfVariants = this.productData.listOfVariants;
      this.arrowKeyLocationVariant = -1;
    }
  }

  setProductImage() {
    this.listOfBanners = this.productData.listOfProductImages;
  }
  async filterProductImage(ev: any) {
    this.setProductImage();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.listOfBanners = await this.listOfBanners.filter(function (item) {
        return item.image.toLowerCase().includes(val.toLowerCase());
      });
      console.log(this.listOfBanners);
      this.arrowKeyLocationVariant = -1;
    } else {
      this.listOfBanners = this.productData.listOfProductImages;
      this.arrowKeyLocationVariant = -1;
    }
  }

  keyDownVariant(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.itemsValue && this.itemsValue.length > 0) {
          if (this.arrowKeyLocationVariant != 0) {
            this.arrowKeyLocationVariant--;
            // this.manageSelectedProduct = this.items[this.arrowKeyLocation].name;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.itemsValue && this.itemsValue.length > 0) {
          if (this.listOfVariants.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocationVariant++;
          }
          if (this.itemsValue.length > this.arrowKeyLocation) {
            // this.manageSelectedProduct = this.items[this.arrowKeyLocation].name;
            break;
          } else {
            break;
          }
        }
        if (this.listOfVariants.length > this.arrowKeyLocationVariant) {
          if (this.listOfVariants.length != this.arrowKeyLocationVariant + 1) {
            this.arrowKeyLocationVariant++;
          }
          break;
        } else {
          break;
        }
    }
    console.log(this.arrowKeyLocationVariant);
  }
  //ENd Inner Search

  async updateProduct() {
    let validate = await this.validateControls();
    if (!validate) {
      let refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = { name: this.name, id: refCompanyId, description: this.description, allowInventory: this.allowInventory, sku: this.productSku, productId: this.id, shippingWeight: parseInt(this.shippingWeight), liveStatus: this.liveStatus };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.updateProduct(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let userData = res.json();
        console.log(userData);
        if (userData.error) {
          if (userData.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({
              message: userData.error,
              duration: 3000,
              position: "top",
              color: "danger"
            });
            await toast.present();
          }
        } else {
          this.flgBtn = "";
          // await this.getAllProducts();
          await this.searchProductDetail(this.name);
          let toast = await this.toastCtrl.create({
            message: "Product parameter updated successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          await toast.present();
        }
      }
    }
  }

  //delete Value

  async deleteProductParameterForProduct(item) {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    let jsonObj = {
      PPvMId: this.id,
      ProductId: item.id,
      id: refCompanyId
    };
    await this.httpApiService.showLoading();
    res = await this.httpApiService.deleteProductParameterForProduct(jsonObj);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({
          message: resp.error,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        toast.present();
      } else {
        this.valueSearch = "";
        this.searchProductDetail(this.manageSelectedProduct);
        let toast = await this.toastCtrl.create({
          message: "Product parameter deleted successfully",
          duration: 2000,
          position: "top",
          color: "success"
        });
        await toast.present();
      }
    }
  }

  async deleteProductVariant(item) {
    console.log("item", item);
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.deleteProductVariant(item.id, refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        // this.variantSearch = "";
        await this.searchProductDetail(this.manageSelectedProduct);
        let toast = await this.toastCtrl.create({ message: "Product variant deleted successfully", duration: 2000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }

  async openModal(item, flagModal) {
    if (flagModal == "addTags" && item == 0) {
      item = this.listOfTags;
    }
    let modalPage = await this.modalCtrl.create({ component: ManageProductPage, componentProps: { id: this.id, item: item, productName: this.manageSelectedProduct, flagModal: flagModal } });
    modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    await modalPage.present();
  }

  async handleModalDismiss(d) {
    console.log("handleModalDismiss ", d);
    this.valueSearch = "";
    this.getProductDetail(this.manageSelectedProduct)
    // await this.getAllProducts();
    if (!!d.data) {
      await this.searchProductDetail(this.manageSelectedProduct);
    }
  }

  async showButton(name) {
    if (name == "description") {
      this.flgBtn = "description";
    } else if (name == "name") {
      this.flgBtn = "name";
    } else if (name == "productSku") {
      this.flgBtn = "productSku";
    } else if (name == "shippingWeight") {
      this.flgBtn = "shippingWeight";
    } else if (name == 'allowInventory') {
      this.flgBtn = "allowInventory"
    } else if (name == 'liveStatus') {
      this.flgBtn = "liveStatus"
    }

  }

  async validateControls() {
    if (this.name != "" && this.description != "" && this.productSku != "") {
      return false;
    } else {
      let toast = await this.toastCtrl.create({ message: "Please insert the detail", duration: 2000, position: "top", color: "danger" });
      await toast.present();
      return true;
    }
  }
  async deleteProductImage(row) {
    console.log("row", row);
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.removeProductImage(row.id, refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        // this.variantSearch = "";
        await this.searchProductDetail(this.manageSelectedProduct);
        let toast = await this.toastCtrl.create({ message: "Product image deleted successfully", duration: 2000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }

  setTags() {
    this.listOfTags = this.productData.listOfTags;
  }

  async filterTag(ev: any) {
    this.setTags();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.listOfTags = await this.listOfTags.filter(function (item) {
        return item.name.toLowerCase().includes(val.toLowerCase());
      });
      console.log(this.listOfTags);
      this.arrowKeyLocationTag = -1;
    } else {
      this.listOfTags = this.productData.listOfTags;
      this.arrowKeyLocationTag = -1;
    }
  }

  async deleteProductTag(item) {
    console.log("item", item);
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.deleteProductTagsForProduct(item.productTagId, refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        // this.variantSearch = "";
        await this.searchProductDetail(this.manageSelectedProduct);
        let toast = await this.toastCtrl.create({ message: "Product tags deleted successfully", duration: 2000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }

  async reset() {
    this.flgBtn = "";
    this.name = "";
    this.selectedProduct = "";
    this.description = "";
    this.listOfValue = [];
    this.listOfVariants = [];
    this.listOfBanners = [];
    this.productSku = "";
    this.listOfTags = [];
    this.manageSelectedProduct = "";
  }

  array_move(arr, old_index, new_index) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
  }

  async reorder(index, position) {
    console.log(index, position, this.listOfBanners);
    // let images = this.listOfBanners;
    // images = images.map(res => res.id);
    // index = images.indexOf(index);
    let j = index;
    if (position == "left") {
      --j;
    }
    if (position == "right") {
      ++j;
    }
    let array = this.listOfBanners;
    this.listOfBanners = [];
    let refCompanyId = await this.storage.get("refCompanyId");
    setTimeout(() => {
      this.listOfBanners = this.array_move(array, index, j);
      this.httpApiService.productReorder(this.listOfBanners, refCompanyId, this.id);
      this.toastCtrl.create({ message: "Product Reorder successfully", duration: 2000, position: "top", color: "success" });
    });
    // this.productData.data[0].listOfProductImages = this.array_move(this.productData.data[0].listOfProductImages, index, j);
  }

  async move(index, position) {
    let j = 0;
    if (position == "left") {
      j = 0;
    }
    if (position == "right") {
      j = this.listOfBanners.length - 1;
    }
    let array = this.listOfBanners;
    this.listOfBanners = [];
    let refCompanyId = await this.storage.get("refCompanyId");
    setTimeout(() => {
      this.listOfBanners = this.array_move(array, index, j);
      this.httpApiService.productReorder(this.listOfBanners, refCompanyId, this.id);
      this.toastCtrl.create({ message: "Product Reorder successfully", duration: 2000, position: "top", color: "success" });
    });
  }

  async delete() {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel:");
          }
        },
        {
          text: "Okay",
          handler: () => {
            console.log("Confirm Okay", this.id);
            this.deleteProduct();
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteProduct() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.deleteProduct(this.id, refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.reset();
        let toast = await this.toastCtrl.create({ message: "Product deleted successfully", duration: 2000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }

  async outofStock(id, value) {
    let res: any;
    await this.httpApiService.showLoading();
    let newValue = 0;
    debugger;
    switch (value) {
      case 0:
      case "0":
        newValue = 1;
        break;
      case 1:
      case "1":
        newValue = 0;
        break;
    }
    res = await this.httpApiService.outOfStock(id, newValue);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.reset();
        let toast = await this.toastCtrl.create({ message: "Product Out of stock successfully", duration: 2000, position: "top", color: "success" });
        await toast.present();
      }
    }
  }
  async notifyUsers() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let resComp = await this.httpApiService.getCompanyDetailById(refCompanyId);
    let responseData = resComp.json().data;
    let compData = responseData[0].companyRes;
    compData = compData.companyLogo;

    debugger;

    let resp = await this.httpApiService.getUsersWithSFPlayerID(refCompanyId);
    let resUsers = resp.json();

    let usersToSend = [];
    if (resUsers.isSuccess && !!resUsers.data && resUsers.data.length > 0) {
      debugger;
      usersToSend = resUsers.data.map(u => u.id);
      var req = {
        refUserId: usersToSend,
        refCompanyId: refCompanyId,
        subject: "New Product Launched!!!",
        title: this.name,
        postBodyData: {
          // to post data with notification
          type: "New Product Launched",
          refData: this.id
        },
        small_icon: compData,
        large_icon: compData,
        isAddInNotification: false,
        type: "New Product Launched"
      };
      debugger;
      let res = await this.httpApiService.sendPushNotificationSF(req);
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        //create card with refUserId = -1
        var reqNotification = {
          title1: "New Product Launched",
          title2: this.name,
          desc: null,
          type: "New Product Launched",
          status: "",
          ReadStatus: null,
          isDeleted: null,
          entDate: new Date(),
          refCompanyId: 1,
          refUserId: -1,
          refData: this.id + "/" + this.name
        };
        debugger;
        let resNot = await this.httpApiService.createCard(reqNotification);
        let toast = await this.toastCtrl.create({ message: "Notification sent", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
    } else {
      let toast = await this.toastCtrl.create({ message: "No users found", duration: 3000, position: "top", color: "danger" });
    }
  }

  async presentPopover(ev: any, type) {
    if (type) {
      let remarks = this.transformTypes.filter(t => t.name == type)[0].remarks;
      console.log(remarks)
      let popover = await this.popoverController.create({
        component: PopoverComponent,
        event: ev,
        componentProps: { text: remarks },
        translucent: true
      });

      return await popover.present();
    }

  }

  async hidePopover() {
    alert('pp')
    await this.popoverController.dismiss();
  }

}
