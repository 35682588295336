import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, NavParams, ToastController, Events } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { Storage } from "@ionic/storage";
import { CodeEditorComponent } from "../../codeEditor/code-editor/code-editor.component";

@Component({
  selector: "app-manage-product-collections",
  templateUrl: "./manage-product-collections.page.html",
  styleUrls: ["./manage-product-collections.page.scss"]
})
export class ManageProductCollectionsPage implements OnInit {
  public name: any;
  public description: any = "";
  public fetchData: any;
  public fetchId: any;
  public flag: any;
  public selectedVariant: any;
  public allProduct: any;
  public id: any;
  public selectedPC: any;
  public result: any;
  public PCIdList: any = [];
  public title: any = "Add Product Collection";
  public skuList: any = [];
  public segment: any;
  public config = "";
  public myWidget: any;
  public myWidget1: any;
  public enableCloudinaryImage = false;
  public presetName = "";
  public shortImagePublicIds = [];
  public coverImagePublicIds = [];
  public collectionOrderOptions: any = [];
  public collectionOrderBy: any;
  public isPopular = false;

  constructor(public modalCtrl: ModalController, public navParams: NavParams, public httpApiService: HttpApiService, public storage: Storage, public authService: AuthenticationService, public toastCtrl: ToastController, public events: Events) {
    this.events.subscribe("selected-values", data => {
      this.PCIdList = [];
      if (data.length > 0) {
        data.forEach(element => {
          this.PCIdList.push(element);
        });
      }
      console.log("this.PCIdList", data, this.PCIdList);
    });
  }

  async ngOnInit() {
    this.flag = this.navParams.get("flag");
    this.id = this.navParams.get("id");
    this.fetchData = this.navParams.get("data");
    if (this.fetchData) {
      this.fetchData.forEach(element => {
        this.PCIdList.push(element);
      });
      console.log("temp", this.PCIdList);
    }
    await this.getAllCollectionOrderOptions();
    await this.getAllProducts();
    console.log("this.fetchData", this.fetchData);
    console.log("this.flag", this.flag);
    if (this.flag == 0) {
      this.title = "Add Product";
      await this.getAllProductVariant();
    }
    await this.getConfig();
    this.myWidget = (window as any).cloudinary.createUploadWidget(
      {
        cloudName: "dealerclub",
        uploadPreset: this.presetName
      },
      (error, result) => {
        if (error) {
          console.log(error);
        }
        if (!error && result && result.event === "success") {
          console.log("Done! Here is the image info: ", result.info);
          if (result.info) {
            let id = "/" + result.info["public_id"];
            //this.selectedImages.push()
            this.shortImagePublicIds.push(id);
          }
        }

        if (!error && result && result.event === "close") {
        }
      }
    );

    this.myWidget1 = (window as any).cloudinary.createUploadWidget(
      {
        cloudName: "dealerclub",
        uploadPreset: this.presetName
      },
      (error, result) => {
        if (error) {
          console.log(error);
        }
        if (!error && result && result.event === "success") {
          console.log("Done! Here is the image info: ", result.info);
          if (result.info) {
            let id = "/" + result.info["public_id"];
            //this.selectedImages.push()
            this.coverImagePublicIds.push(id);
          }
        }

        if (!error && result && result.event === "close") {
        }
      }
    );

  }

  async getConfig() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getCompanyDetailById(refCompanyId);
    this.httpApiService.companyObj = res.json().data[0].companyRes;
    this.setConfig();
  }

  async setConfig() {
    if (this.httpApiService.companyObj && this.httpApiService.companyObj.config) {
      let companyJson = this.httpApiService.companyObj.config;
      console.log(companyJson);
      if (!!companyJson) {
        companyJson = JSON.parse(companyJson);
        if (!!companyJson) {
          if (!!companyJson.enableCloudinaryImage) {
            this.enableCloudinaryImage = companyJson.enableCloudinaryImage;
          }

          if (!!companyJson.presetName) {
            this.presetName = companyJson.presetName;
          }
        }
      }
    } else {
      console.log("---");
    }
  }

  async getAllProducts() {
    await this.httpApiService.showLoading();
    let refCompanyId = await this.storage.get("refCompanyId");
    let res = await this.httpApiService.getAllProducts(refCompanyId);
    await this.httpApiService.hideLoading();
    console.log("res", res.json());
    this.allProduct = res.json().data;
    console.log("getAllProducts", this.allProduct);
  }
  async getAllProductVariant() {
    let refCompanyId = await this.storage.get("refCompanyId");
    // let res = await this.httpApiService.getAllProductVariants(refCompanyId);
    // console.log("res", res.json());
    // this.allProduct = res.json().data;
    // console.log("allProductVariant", this.allProduct);
  }
  comparer(otherArray) {
    return function (current) {
      return (
        otherArray.filter(function (other) {
          return other.id == current.id && other.title == current.title;
        }).length == 0
      );
    };
  }
  closeModal() {
    this.modalCtrl.dismiss();
  }
  async addProductCollection() {
    let refCompanyId = await this.storage.get("refCompanyId");
    let objData = { name: this.name, description: this.description, id: refCompanyId, config: this.config, shortImage: this.shortImagePublicIds.length > 0 ? this.shortImagePublicIds[0] : null, coverImage: this.coverImagePublicIds.length > 0 ? this.coverImagePublicIds[0] : null, collectionOrderBy: this.collectionOrderBy, isPopular: this.isPopular };
    console.log("objData", objData);
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.createProductCollection(objData);
    await this.httpApiService.hideLoading();
    let resp = res.json();
    if (resp.status == 401) {
      this.authService.logout();
    } else {
      if (resp.error) {
        let toast = await this.toastCtrl.create({
          message: resp.error,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        toast.present();
      } else {
        this.resetForm();
        let toast = await this.toastCtrl.create({
          message: "Collection created successfully",
          duration: 3000,
          position: "top",
          color: "success"
        });
        toast.present();
      }
    }
  }
  async resetForm() {
    this.name = "";
    this.description = "";
    this.shortImagePublicIds = [];
    this.coverImagePublicIds = [];
  }
  async addUpdateProductCollection() {
    let products = [];
    this.PCIdList.forEach(product => {
      products.push(product.id);
    });
    console.log("selectedProductCollection", this.PCIdList, products);
    let refCompanyId = await this.storage.get("refCompanyId");
    let data = { id: refCompanyId, PIdList: products, PCId: this.id };
    console.log("data", data);
    await this.httpApiService.showLoading();
    let res = await this.httpApiService.addProductCollections(data);
    await this.httpApiService.hideLoading();
    let resp = res.json();
    if (resp.status == 401) {
      this.authService.logout();
    } else {
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.resetForm();
        let toast = await this.toastCtrl.create({ message: "Added successfully", duration: 3000, position: "top", color: "success" });
        toast.present();
      }
    }
    this.closeModal();
  }

  async addUpdateProductCollectionUsingSKU() {
    console.log("skuList ", this.skuList);
    if (!!this.skuList && this.skuList !== "" && this.skuList.length > 0) {
      let sku = this.skuList.split("\n");
      console.log("sku ", sku);
      let refCompanyId = await this.storage.get("refCompanyId");
      let data = { id: refCompanyId, skuList: sku, PCId: this.id };
      console.log("data", data);
      await this.httpApiService.showLoading();
      let res = await this.httpApiService.addProductCollectionsUsingSKU(data);
      await this.httpApiService.hideLoading();
      let resp = res.json();
      if (resp.status == 401) {
        this.authService.logout();
      } else {
        if (resp.error) {
          let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
          toast.present();
        } else {
          this.resetForm();
          let toast = await this.toastCtrl.create({ message: "Added successfully", duration: 3000, position: "top", color: "success" });
          toast.present();
        }
      }
      this.closeModal();
    } else {
      let toast = await this.toastCtrl.create({ message: "Please enter product SKU.", duration: 3000, position: "top", color: "danger" });
      toast.present();
    }
  }
  async segmentChanged(ev: any) {
    console.log('Segment changed', ev);
    this.segment = ev.target.value;
  }
  async addConfig() {
    console.log("add config");

    const modal = await this.modalCtrl.create({
      component: CodeEditorComponent,
      componentProps: { code: this.config, title: this.title, subtitle: "Config" }
    });
    modal.onDidDismiss().then(data => {
      console.log("data", data.data, typeof (data.data));
      if (!!data.data) {
        this.config = data.data;
        // this.updateBlock(block);
      }
    });
    return await modal.present();
  }

  async shortImageUpload() {
    this.myWidget.open();
  }

  async coverImageUpload() {
    this.myWidget1.open();
  }

  async getAllCollectionOrderOptions() {
    let res = await this.httpApiService.getAllCollectionOrderOptions();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.collectionOrderOptions = res.json().data;
      console.log("this.collectionOrderOptions ", this.collectionOrderOptions);
    }
  }
}
