import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, NavParams, ToastController } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { CodeEditorComponent } from "../../codeEditor/code-editor/code-editor.component";

@Component({
  selector: "app-manage-shop-page",
  templateUrl: "./manage-shop-page.page.html",
  styleUrls: ["./manage-shop-page.page.scss"]
})
export class ManageShopPagePage implements OnInit {
  public isModal: any;
  public page = "";
  public item: any;
  public pageId: any;
  public newPageName = "";
  public imageLink: File = null;
  public customHTML = "";
  public imageRedirectTo = "";
  public sectionName = "";
  public sectionImageName = "";
  public name = "";
  public sectionType = "";
  public sectionId = "";
  public allSectionType: any;
  public defaultPage: boolean;
  public order = 0;
  public config = "";
  public showSectionName = false;
  public sectionOrder = 0;
  public showPrice = false;
  public noOfProducts = 0;
  public collectionName = "";
  public redirectToProduct = false;
  public myWidget: any;
  public imagePublicIds = [];
  public enableCloudinaryImage = false;
  public presetName = "";
  public gravityoptions: any;
  public allTypes = [];
  public transformationType: any;
  public selectedImages = [];

  constructor(private modalCtrl: ModalController, public storage: Storage, private navParams: NavParams, public ConfigService: ConfigService, public authService: AuthenticationService, private toastCtrl: ToastController, private nav: NavController, public httpApiService: HttpApiService) { }

  async getTransformationTypes() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getTransformationTypes(refCompanyId);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.allTypes = res.json().data;
    }
  }
  async getConfig() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getCompanyDetailById(refCompanyId);
    this.httpApiService.companyObj = res.json().data[0].companyRes;
    this.setConfig();
  }

  async setConfig() {
    if (this.httpApiService.companyObj && this.httpApiService.companyObj.config) {
      let companyJson = this.httpApiService.companyObj.config;
      console.log(companyJson);
      if (!!companyJson) {
        companyJson = JSON.parse(companyJson);
        if (!!companyJson) {
          if (!!companyJson.enableCloudinaryImage) {
            this.enableCloudinaryImage = companyJson.enableCloudinaryImage;
          }

          if (!!companyJson.presetName) {
            this.presetName = companyJson.presetName;
          }
        }
      }
      // let companyJson = this.httpApiService.companyObj.data.config;
      // console.log(this.httpApiService.companyObj)
      // if (!!this.httpApiService.companyObj.data.enableCloudinaryImage) {
      //   this.enableCloudinaryImage = this.httpApiService.companyObj.data.enableCloudinaryImage
      // }

      // if (!!this.httpApiService.companyObj.data.presetName) {
      //   this.presetName = this.httpApiService.companyObj.data.presetName
      // }

      // alert(this.presetName)
    } else {
      console.log("---");
    }
  }
  async cloudinaryUpload() {
    this.myWidget.open();
  }
  async ngOnInit() {
    this.isModal = this.navParams.get("flagModal");
    this.pageId = this.navParams.get("id");
    this.page = this.navParams.get("pageName");
    this.item = this.navParams.get("item");
    this.allSectionType = this.navParams.get("allSectionType");
    await this.getConfig();

    this.myWidget = (window as any).cloudinary.createUploadWidget(
      {
        cloudName: "dealerclub",
        uploadPreset: this.presetName,
        multiple: false,
        clientAllowedFormats: ["png", "gif", "jpeg", "jpg", "bmp", "mp4"]
      },
      (error, result) => {
        if (error) {
          console.log(error);
        }
        if (!error && result && result.event === "success") {
          console.log("Done! Here is the image info: ", result.info);
          if (result.info) {
            let id = "/" + result.info["public_id"];
            //this.selectedImages.push()
            this.imagePublicIds = []; //bcz we want single image here
            this.imagePublicIds.push(id);

            this.imageLink = this.imagePublicIds[0];
          }
        }

        if (!error && result && result.event === "close") {
        }
      }
    );

    await this.getTransformationTypes();

    console.log(this.item, this.page, this.pageId);
    if (this.isModal == "updateBanner") {
      this.name = this.item.name;
      // this.getSectionDetailById(this.item.id);
    } else if (this.isModal == "updateSectionImage") {
      this.sectionImageName = this.item.name;

      this.imageLink = this.item.link;
      this.imageRedirectTo = this.item.redirectTo;
      this.order = this.item.order;
      this.config = this.item.config;
      if (this.item.customHTML) {
        this.customHTML = this.item.customHTML;
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss(this.isModal);
  }

  async addNewPage() {
    console.log("add page", this.newPageName, this.defaultPage);
    let validate = await this.validateControls("page");
    if (!validate) {
      let refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = { id: refCompanyId, name: this.newPageName, isDefault: this.defaultPage };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.createPage(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json();
        console.log(data);
        if (data.error) {
          if (data.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        } else {
          let toast = await this.toastCtrl.create({ message: "Page added successfully", duration: 3000, position: "top", color: "success" });
          await toast.present();
          this.reset();
        }
      }
    }
  }

  async addSection() {
    console.log("add section", this.sectionName, this.sectionType);
    let validate = await this.validateControls("section");
    if (!validate) {
      let refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = {
        id: refCompanyId,
        typeId: this.sectionType,
        pageId: this.pageId,
        sectionName: this.sectionName,
        showSectionName: this.showSectionName,
        order: this.sectionOrder,
        showPrice: this.showPrice,
        noOfProducts: this.noOfProducts,
        collectionName: this.collectionName,
        redirectToProduct: this.redirectToProduct
      };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.createSection(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json();
        console.log(data);
        if (data.error) {
          if (data.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        } else {
          let toast = await this.toastCtrl.create({ message: "Banner added successfully", duration: 3000, position: "top", color: "success" });
          await toast.present();
          this.reset();
        }
      }
    }
  }

  async addSectionImage() {
    console.log("add section image", this.sectionImageName, this.imageLink, this.imageRedirectTo);
    let validate = await this.validateControls("sectionImage");
    if (!validate) {
      let refCompanyId = await this.storage.get("refCompanyId");
      let customHTML = "";

      if (this.customHTML) {
        customHTML = JSON.stringify(this.customHTML);
      }
      console.log(this.customHTML)
      console.log(customHTML)
      let jsonObj: any = { id: refCompanyId, sectionId: this.item, name: this.sectionImageName, redirectTo: this.imageRedirectTo, config: JSON.stringify(this.config), order: this.order, customHTML: customHTML };
      if (this.enableCloudinaryImage) {
        jsonObj.imageLink = this.imageLink;

        console.log(jsonObj);
        let res: any;
        await this.httpApiService.showLoading();
        res = await this.httpApiService.createSectionImageWithImagePath(jsonObj);
        await this.httpApiService.hideLoading();
        if (res.status == 401) {
          this.authService.logout();
        } else {
          let data = res.json();
          console.log(data);
          if (data.error) {
            if (data.error == "invalid_token") {
              this.modalCtrl.dismiss();
              this.authService.logout();
            } else {
              let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
              await toast.present();
            }
          } else {
            let toast = await this.toastCtrl.create({ message: "Section Image added successfully", duration: 3000, position: "top", color: "success" });
            await toast.present();
            this.reset();
          }
        }
      } else {
        const formData: FormData = new FormData();
        formData.append("file", this.imageLink);
        console.log("formData ", formData);
        (jsonObj.formData = formData), console.log(jsonObj);
        let res: any;
        await this.httpApiService.showLoading();
        res = await this.httpApiService.createSectionImage(jsonObj);
        await this.httpApiService.hideLoading();
        if (res.status == 401) {
          this.authService.logout();
        } else {
          let data = res.json();
          console.log(data);
          if (data.error) {
            if (data.error == "invalid_token") {
              this.modalCtrl.dismiss();
              this.authService.logout();
            } else {
              let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
              await toast.present();
            }
          } else {
            let toast = await this.toastCtrl.create({ message: "Section Image added successfully", duration: 3000, position: "top", color: "success" });
            await toast.present();
            this.reset();
          }
        }
      }
    }
  }


  async updateSectionImage() {
    console.log("update section image", this.sectionImageName, this.imageLink, this.imageRedirectTo);
    let validate = await this.validateControls("sectionImage");
    if (!validate) {
      let refCompanyId = await this.storage.get("refCompanyId");
      let customHTML = "";
      if (this.customHTML) {
        customHTML = JSON.stringify(this.customHTML);
      }
      let jsonObj: any = { id: refCompanyId, sectionId: this.item.refSectionID, sectionImageId: this.item.id, name: this.sectionImageName, redirectTo: this.imageRedirectTo, config: JSON.stringify(this.config), order: this.order, customHTML: customHTML };

      if ((this, this.enableCloudinaryImage && !!this.imageLink)) jsonObj.imageLink = this.imageLink;
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.updateSectionImage(jsonObj);

      await this.httpApiService.hideLoading();
      if (res.status == 401) {
        this.authService.logout();
      } else {
        let data = res.json();
        console.log(data);
        if (data.error) {
          if (data.error == "invalid_token") {
            this.modalCtrl.dismiss();
            this.authService.logout();
          } else {
            let toast = await this.toastCtrl.create({ message: data.error, duration: 3000, position: "top", color: "danger" });
            await toast.present();
          }
        } else {
          let toast = await this.toastCtrl.create({ message: "Section Image updated successfully", duration: 3000, position: "top", color: "success" });
          await toast.present();
        }
      }
    }
  }

  async redirectTo(link) {
    console.log("link", link);
    window.open(link, "_blank");
  }

  async validateControls(page) {
    if ((page == "page" && this.newPageName != "") || (page = "section" && this.sectionName != "" && this.sectionType != "") || (page = "sectionImage" && this.sectionImageName != "" && this.imageRedirectTo != "")) {
      return false;
    } else {
      let toast = await this.toastCtrl.create({ message: "Please insert the details", duration: 2000, position: "top", color: "danger" });
      await toast.present();
      return true;
    }
  }

  async reset() {
    this.newPageName = "";
    this.sectionType = "";
    this.sectionName = "";
    this.sectionImageName = "";
    this.imageRedirectTo = "";
    this.imageLink = null;
    this.sectionOrder = 0;
    this.showSectionName = false;
    this.showPrice = false;
    this.noOfProducts = 0;
    this.collectionName = "";
    this.redirectToProduct = false;
  }
  onFileSelected(event) {
    this.imageLink = <File>event.target.files[0];
    console.log("image link", this.imageLink);
  }

  async addConfig() {
    console.log("add config");

    const modal = await this.modalCtrl.create({
      component: CodeEditorComponent,
      componentProps: { code: this.config, title: "Add Section Image", subtitle: "Config" }
    });
    modal.onDidDismiss().then(data => {
      console.log("data", data.data, typeof data.data);
      if (!!data.data) {
        this.config = data.data;
        // this.updateBlock(block);
      } else {
        this.config = ""
      }
    });
    return await modal.present();
  }
}
