import { Component, OnInit } from "@angular/core";
import { NavController, ModalController, NavParams, ToastController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";


@Component({
  selector: "app-manage-product",
  templateUrl: "./manage-product.page.html",
  styleUrls: ["./manage-product.page.scss"]
})
export class ManageProductPage implements OnInit {
  public isModal: any;
  public name = "";
  public value = "";
  public product = "";
  public item: any;
  public items = [];
  public refCompanyId: any;
  public getAllProductParam: any;
  public listOfValue: any;
  public PPvMId: any;
  public productId: any;
  public newProductName = "";
  public productDescription = "";
  public productParamName: any;
  public productParamValue: any;
  public productParamList: any = [];
  public variantTitle = "";
  public variantSku = "";
  public variantQty: any;
  public variantPrice: any;
  public getProductVariantData: any;
  public imageLink: File = null;
  public productSku = "";
  public isVisible: boolean = true;
  public allProductTags: any;
  public selectedTags: any = [];
  public compareAtPrice: any;
  public shippingWeight: any = 0;
  public addOns: any;
  public variantAddons: any;
  public addOnsList: any;
  public addOnsId: any;
  public showAddons: boolean = false;
  public btnString = "Show Add Ons";
  public myWidget: any;
  public imagePublicIds = [];
  public enableCloudinaryImage = false;
  public presetName = "";
  public gravityoptions: any;
  public allTypes = [];
  public transformationType: any;
  public transformType_search: any;
  public transformType_collection: any;
  public transformType_slider: any;
  public selectedImages = [];

  constructor(
    private modalCtrl: ModalController,
    public storage: Storage,
    private navParams: NavParams,
    public ConfigService: ConfigService,
    public authService: AuthenticationService,
    private toastCtrl: ToastController,
    private nav: NavController,
    public httpApiService: HttpApiService,
    public events: Events

    //private window: Window
  ) {
    this.events.subscribe("selected-values", data => {
      this.selectedTags = data;
    });
  }

  async getConfig() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getCompanyDetailById(refCompanyId);
    this.httpApiService.companyObj = res.json().data[0].companyRes;
    this.setConfig();
  }

  async setConfig() {
    if (this.httpApiService.companyObj && this.httpApiService.companyObj.config) {
      let companyJson = this.httpApiService.companyObj.config;
      console.log(companyJson);
      if (!!companyJson) {
        companyJson = JSON.parse(companyJson);
        if (!!companyJson) {
          if (!!companyJson.enableCloudinaryImage) {
            this.enableCloudinaryImage = companyJson.enableCloudinaryImage;
          }

          if (!!companyJson.presetName) {
            this.presetName = companyJson.presetName;
          }
        }
      }
      // let companyJson = this.httpApiService.companyObj.data.config;
      // console.log(this.httpApiService.companyObj)
      // if (!!this.httpApiService.companyObj.data.enableCloudinaryImage) {
      //   this.enableCloudinaryImage = this.httpApiService.companyObj.data.enableCloudinaryImage
      // }

      // if (!!this.httpApiService.companyObj.data.presetName) {
      //   this.presetName = this.httpApiService.companyObj.data.presetName
      // }

      // alert(this.presetName)
    } else {
      console.log("---");
    }
  }


  async getTransformationTypes() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getTransformationTypes(refCompanyId);
    if (res.status == 0) {
      console.log("error");
    } else {
      this.allTypes = res.json().data;
    }
  }

  async ngOnInit() {
    await this.getConfig();
    //let windows: any = this.window;
    this.myWidget = (window as any).cloudinary.createUploadWidget(
      {
        cloudName: "dealerclub",
        uploadPreset: this.presetName
      },
      (error, result) => {
        if (error) {
          console.log(error);
        }
        if (!error && result && result.event === "success") {
          console.log("Done! Here is the image info: ", result.info);
          if (result.info) {
            let id = "/" + result.info["public_id"];
            //this.selectedImages.push()
            this.imagePublicIds.push(id);
          }
        }

        if (!error && result && result.event === "close") {
        }
      }
    );
    await this.getTransformationTypes();
    await this.getAllProductParameter();

    this.isModal = this.navParams.get("flagModal");
    this.productId = this.navParams.get("id");
    this.product = this.navParams.get("productName");
    this.item = this.navParams.get("item");
    this.imageLink = this.item.image;
    console.log("items", this.item, this.product, this.productId, this.isModal);
    if (this.isModal == "updateValue") {
      this.name = this.item.paramName;
      // this.PPvMId = this.item.paramValue;
      // this.onParamSelect(this.name);
      console.log("update value", this.name, this.PPvMId);
    } else if (this.isModal == "updateVariant") {
      await this.getVariantsAddons();
      await this.getAllAddons();
      this.variantTitle = this.item.title;
      this.variantSku = this.item.sku;
      this.variantQty = this.item.quantity;
      this.variantPrice = this.item.price;
      this.compareAtPrice = this.item.compareAtPrice;
      if (this.item.listOfProductVariantParameter.length > 0) {
        this.productParamName = this.item.listOfProductVariantParameter[0].name;
        this.productParamValue = this.item.listOfProductVariantParameter[0].value;
        this.item.listOfProductVariantParameter.forEach(param => {
          this.productParamList.push({ paramName: param.name, name: param.value, value: param.id });
        });
      }
    } else if (this.isModal == "addTags") {
      if (this.item.length > 0) {
        this.item.forEach(tag => {
          this.selectedTags.push({ type: "Product", name: tag.name, id: tag.tagId });
        });
      }
      await this.getAllProductTags();
    } else {
      this.productParamList = [];
    }
  }

  showHide() {
    this.showAddons = !this.showAddons;
    if (this.showAddons) {
      this.btnString = "Hide Add Ons";
    } else {
      this.btnString = "Show Add Ons";
    }
  }

  async updateAddOn(event, addOn) {
    let addOnId = addOn.id;
    let refPvID = this.item.id;

    if (event.checked == true) {
      //insert addons
      let res: any;
      let refCompanyId = await this.storage.get("refCompanyId");
      res = await this.httpApiService.addVariantAddons(refCompanyId, refPvID, addOnId);
      if (res.status == 401) {
        this.authService.logout();
      }
    }

    if (event.checked == false) {
      //delete from table
      let res: any;
      let refCompanyId = await this.storage.get("refCompanyId");
      res = await this.httpApiService.removeVariantAddons(refCompanyId, refPvID, addOnId);
      if (res.status == 401) {
        this.authService.logout();
      }
    }
  }

  async getVariantsAddons() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getVariantsAddons(refCompanyId, this.item.id);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.variantAddons = res.json().data;
    }
  }

  async getAllAddons() {
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getAllAddons(refCompanyId);
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.addOns = res.json().data;
      let i = this.variantAddons;
      this.addOns.map(function (x) {
        var result = i.filter(a1 => a1.refAddOnsId == x.id);
        if (result.length > 0) {
          x.selected = true;
        }
        return x;
      });
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async getAllProductParameter() {
    await this.httpApiService.showLoading();
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getAllProductParameter(refCompanyId);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getAllProductParam = res.json().data;
      this.items = this.getAllProductParam;
      if (this.isModal == "updateValue" || this.isModal == "updateVariant") {
        this.onParamSelect(this.name);
        this.PPvMId = this.item.paramValue;
        if (this.item.listOfProductVariantParameter && this.item.listOfProductVariantParameter.length > 0) {
          this.productParamValue = this.item.listOfProductVariantParameter[0].value;
        }
      }
      console.log("get All product param Data", this.getAllProductParam);
    }
  }

  async getProductVariantDetailsById(productId, PvID) {
    await this.httpApiService.showLoading();
    let res: any;
    let refCompanyId = await this.storage.get("refCompanyId");
    res = await this.httpApiService.getProductVariantById(refCompanyId, productId, PvID);
    await this.httpApiService.hideLoading();
    if (res.status == 401) {
      this.authService.logout();
    } else {
      this.getProductVariantData = res.json().data;
      this.items = this.getProductVariantData;
      // if (this.isModal == "updateValue") {
      //   this.onParamSelect(this.name);
      //   this.PPvMId = this.item.paramValue;
      // }
      console.log("get product variant Data", this.getAllProductParam);
    }
  }
  onParamSelect(name) {
    if (name != undefined) {
      this.name = name;
    }
    console.log(this.name, this.productParamValue, typeof this.productParamValue);
    this.productParamValue = "";
    this.PPvMId = "";
    if (this.name != "" || this.productParamName != "") {
      let listOfValue = this.getAllProductParam.filter(param => {
        if (param.name == this.name) {
          return param.shop_productParameterValuesMaster;
        } else if (param.name == this.productParamName) {
          return param.shop_productParameterValuesMaster;
        }
      });
      console.log(listOfValue);
      if (listOfValue[0] && listOfValue[0].shop_productParameterValuesMaster.length > 0) {
        this.listOfValue = listOfValue[0].shop_productParameterValuesMaster;
      } else {
        this.listOfValue = [];
      }
      console.log("list of values", this.listOfValue, this.productParamName, this.productParamValue, this.getAllProductParam);
    }
  }

  async addProductParameter() {
    let validate = await this.validateControls();
    if (!validate) {
      this.refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = {
        PPvMId: this.PPvMId,
        productId: this.productId,
        id: this.refCompanyId
      };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.createProductParameterForProduct(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 0) {
        this.authService.logout();
      } else {
        let resp = res.json();
        if (resp.error) {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          toast.present();
        } else {
          let toast = await this.toastCtrl.create({
            message: "Product Parameter added successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          await toast.present();
          this.resetForm();
        }
      }
    }
  }

  async updateValue() {
    let validate = await this.validateControls();
    if (!validate) {
      this.refCompanyId = await this.storage.get("refCompanyId");
      let jsonObj = {
        id: this.refCompanyId,
        productId: this.productId,
        PPvMId: this.PPvMId
      };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.updateProductParameter(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 0) {
        this.authService.logout();
      } else {
        let resp = res.json();
        if (resp.error) {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          toast.present();
        } else {
          let toast = await this.toastCtrl.create({
            message: "Product Parameter updated successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          await toast.present();
        }
      }
    }
  }

  async addNewProduct() {
    let validate = await this.validateControls();
    if (!validate) {
      this.refCompanyId = await this.storage.get("refCompanyId");
      let productParamValueList = [];
      // if (this.productParamList.length > 0) {
      //   this.productParamList.forEach(param => {
      //     productParamValueList.push(param.value);
      //   });
      // }
      if (!!this.productParamValue && !!this.productParamValue.id) {
        productParamValueList.push(this.productParamValue.id);
      }
      let jsonObj = { id: this.refCompanyId, name: this.newProductName, description: this.productDescription, sku: this.productSku, PPvMIdList: productParamValueList, shippingWeight: parseInt(this.shippingWeight) };
      console.log("addNewProduct ", jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.createProduct(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 0) {
        this.authService.logout();
      } else {
        let resp = res.json();
        if (resp.error) {
          let toast = await this.toastCtrl.create({
            message: resp.error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          toast.present();
        } else {
          let toast = await this.toastCtrl.create({
            message: "Product added successfully",
            duration: 3000,
            position: "top",
            color: "success"
          });
          await toast.present();
          this.resetForm();
        }
      }
    }
  }

  async validateControls() {
    if (this.isModal == "addValue" || this.isModal == "updateValue") {
      if (this.PPvMId != "") {
        return false;
      } else {
        let toast = await this.toastCtrl.create({
          message: "Please select/insert all the information",
          duration: 2000,
          position: "top",
          color: "danger"
        });
        await toast.present();
        return true;
      }
    } else if (this.isModal == "addNewProduct") {
      if (this.newProductName != "" && this.productDescription != "" && this.productSku != "") {
        return false;
      } else {
        let toast = await this.toastCtrl.create({ message: "Please insert the information", duration: 2000, position: "top", color: "danger" });
        await toast.present();
        return true;
      }
    } else if (this.isModal == "addVariant" || this.isModal == "updateVariant") {
      if (this.variantTitle != "" && this.variantSku != "" && this.variantPrice && this.compareAtPrice >= 0 && this.variantQty) {
        return false;
      } else {
        let toast = await this.toastCtrl.create({ message: "Please insert the information", duration: 2000, position: "top", color: "danger" });
        await toast.present();
        return true;
      }
    } else {
      if (this.name != "") {
        return false;
      } else {
        let toast = await this.toastCtrl.create({ message: "Please insert the information", duration: 2000, position: "top", color: "danger" });
        await toast.present();
        return true;
      }
    }
  }

  resetForm() {
    this.value = "";
    this.name = "";
    this.PPvMId = null;
    this.productParamList = [];
    this.newProductName = "";
    this.productDescription = "";
    this.productParamName = "";
    this.productParamValue = "";
    this.variantTitle = "";
    this.variantSku = "";
    this.variantPrice = null;
    this.compareAtPrice = null;
    this.productParamName = "";
    this.productParamValue = "";
    this.imageLink = null;
    this.productSku = "";
    this.variantQty = "";
    this.shippingWeight = null;
  }

  async addParameters() {
    // console.log("product param", this.productParamName, this.productParamValue, this.getAllProductParam, this.items);
    if (this.productParamName != "" && this.productParamName != undefined && this.productParamValue != "" && this.productParamValue != undefined) {
      this.productParamList.push({ paramName: this.productParamName, name: this.productParamValue.value, value: this.productParamValue.id });
      this.items = [];
      this.getAllProductParam.forEach(param1 => {
        var found = false;
        this.productParamList.forEach(param2 => {
          if (param1.name === param2.paramName) {
            found = true;
          }
        });
        if (!found) {
          this.items.push(param1);
        }
      });
      this.isVisible = false;
      setTimeout(() => (this.isVisible = true));
      // console.log("product param list", this.productParamList);
    } else {
      let toast = await this.toastCtrl.create({
        message: "Please select the parameter",
        duration: 2000,
        position: "top",
        color: "danger"
      });
      await toast.present();
    }
  }

  async addNewProductVariant() {
    console.log("add", this.productParamValue);
    let validate = await this.validateControls();
    if (!validate) {
      this.refCompanyId = await this.storage.get("refCompanyId");
      let productParamValueList = [];
      if (this.productParamList.length > 0) {
        this.productParamList.forEach(param => {
          productParamValueList.push(param.value);
        });
      }
      // productParamValueList.push(this.productParamValue.id);
      let jsonObj = { id: this.refCompanyId, ProductId: this.productId, title: this.variantTitle, sku: this.variantSku, quantity: this.variantQty, price: this.variantPrice, compareAtPrice: this.compareAtPrice, PPvMIdList: productParamValueList };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.addProductVariant(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 0) {
        this.authService.logout();
      } else {
        let resp = res.json();
        if (resp.error) {
          let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
          toast.present();
        } else {
          let toast = await this.toastCtrl.create({ message: "Product variant added successfully", duration: 3000, position: "top", color: "success" });
          await toast.present();
          this.resetForm();
        }
      }
    }
  }

  async removeParamFromList(param) {
    // console.log("this.getAllProductParam", this.getAllProductParam, this.items);
    const index = this.productParamList.indexOf(param, 0);
    if (index > -1) {
      this.productParamList.splice(index, 1);
    }
    this.items = [];
    this.getAllProductParam.forEach(param1 => {
      var found = false;
      this.productParamList.forEach(param2 => {
        if (param1.name === param2.paramName) {
          found = true;
        }
      });
      if (!found) {
        this.items.push(param1);
      }
    });
    this.isVisible = false;
    setTimeout(() => (this.isVisible = true));
    // console.log(param, this.productParamList);
  }

  async updateProductVariant() {
    console.log("product variant", this.variantTitle, this.variantSku, this.variantQty, this.variantPrice, this.compareAtPrice, this.productParamName, this.productParamValue);
    let validate = await this.validateControls();
    if (!validate) {
      let productParamValueList = [];
      this.refCompanyId = await this.storage.get("refCompanyId");
      if (this.productParamList.length > 0) {
        this.productParamList.forEach(param => {
          productParamValueList.push(param.value);
        });
      }
      // productParamValueList.push(this.productParamValue.id);
      let jsonObj = { id: this.refCompanyId, productId: this.productId, PvID: this.item.id, title: this.variantTitle, sku: this.variantSku, quantity: this.variantQty, price: this.variantPrice, compareAtPrice: this.compareAtPrice, PPvMIdList: productParamValueList };
      console.log(jsonObj);
      let res: any;
      await this.httpApiService.showLoading();
      res = await this.httpApiService.updateProductVariant(jsonObj);
      await this.httpApiService.hideLoading();
      if (res.status == 0) {
        this.authService.logout();
      } else {
        let resp = res.json();
        if (resp.error) {
          let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
          toast.present();
        } else {
          let toast = await this.toastCtrl.create({ message: "Product Variant updated successfully", duration: 3000, position: "top", color: "success" });
          await toast.present();
        }
      }
    }
  }
  async updateProductImage() {
    console.log("Update image data", this.item);
    this.refCompanyId = await this.storage.get("refCompanyId");
    let jsonObj = {
      imageId: this.item.id,
      image: this.imageLink,
      id: this.refCompanyId
    };
    console.log(jsonObj);
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.updateProductImage(jsonObj);
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({
          message: resp.error,
          duration: 3000,
          position: "top",
          color: "danger"
        });
        toast.present();
      } else {
        let toast = await this.toastCtrl.create({
          message: "Image added successfully",
          duration: 3000,
          position: "top",
          color: "success"
        });
        await toast.present();
        this.resetForm();
      }
    }
  }
  async addProductImage() {
    console.log(this.imagePublicIds);

    console.log("data", this.productId);
    this.refCompanyId = await this.storage.get("refCompanyId");
    const formData: FormData = new FormData();

    let res: any;
    await this.httpApiService.showLoading();
    let jsonObj = {};

    if (!this.enableCloudinaryImage) {
      formData.append("file", this.imageLink);
      jsonObj = { productId: this.productId, id: this.refCompanyId, formData: formData };
      res = await this.httpApiService.createProductImage(jsonObj);
    }
    if (this.enableCloudinaryImage) {
      if (this.imagePublicIds.length == 0) {
        let toast = await this.toastCtrl.create({ message: "Please select image", duration: 3000, position: "top", color: "danger" });
        await toast.present();
        await this.httpApiService.hideLoading();

        return;
      } else {
        jsonObj = { productId: this.productId, id: this.refCompanyId, images: this.imagePublicIds, transformationType: this.transformationType, transformType_search: this.transformType_search, transformType_collection: this.transformType_collection, transformType_slider: this.transformType_slider };
        res = await this.httpApiService.createProductImageForCloudinary(jsonObj);
      }
    }

    console.log(jsonObj);
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else if (res.status == 413) {
      let toast = await this.toastCtrl.create({ message: "Payload is too large", duration: 3000, position: "top", color: "danger" });
      toast.present();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        let toast = await this.toastCtrl.create({ message: "Product image added successfully", duration: 3000, position: "top", color: "success" });
        this.imagePublicIds = [];
        await toast.present();
        this.resetForm();
      }
    }
  }

  async getAllProductTags() {
    let res: any;
    this.refCompanyId = await this.storage.get("refCompanyId");
    await this.httpApiService.showLoading();
    res = await this.httpApiService.getAllTagsByType(this.refCompanyId, "Product");
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        this.allProductTags = resp;
        console.log("this.allProductTags", this.allProductTags);
      }
    }
  }

  async addProductTags() {
    console.log("selected tags", this.selectedTags);
    let tagIDList = [];
    if (this.selectedTags.length > 0) {
      this.selectedTags.forEach(tag => {
        tagIDList.push(tag.id);
      });
    }
    console.log("tag id list", tagIDList);
    this.refCompanyId = await this.storage.get("refCompanyId");
    let jsonObj = {
      id: this.refCompanyId,
      productID: this.productId,
      tagIDList: tagIDList
    };
    console.log(jsonObj);
    let res: any;
    await this.httpApiService.showLoading();
    res = await this.httpApiService.addMultipleTagsToProduct(jsonObj);
    await this.httpApiService.hideLoading();
    if (res.status == 0) {
      this.authService.logout();
    } else {
      let resp = res.json();
      if (resp.error) {
        let toast = await this.toastCtrl.create({ message: resp.error, duration: 3000, position: "top", color: "danger" });
        toast.present();
      } else {
        let toast = await this.toastCtrl.create({ message: "Product tags added successfully", duration: 3000, position: "top", color: "success" });
        await toast.present();
        this.resetForm();
      }
    }
  }

  onFileSelected(event) {
    this.imageLink = <File>event.target.files[0];
    console.log("image link", this.imageLink);
  }

  async addProduct() {
    if (this.imageLink) {
      console.log(this.imageLink);
      this.refCompanyId = await this.storage.get("refCompanyId");

      const formData: FormData = new FormData();
      formData.append("file", this.imageLink);
      let res: any;
      let jsonObj = { id: this.refCompanyId, formData: formData };
      await this.httpApiService.showLoading();
      res = await this.httpApiService.addProduct(jsonObj);
      await this.httpApiService.hideLoading();
      console.log("response", res);
      if (res.status == 401) {
        this.authService.logout();
      } else {
        if (res.json().error) {
          let toast = await this.toastCtrl.create({
            message: res.json().error,
            duration: 3000,
            position: "top",
            color: "danger"
          });
          await toast.present();
        } else {
          if (res.json().data) {
            let toast = await this.toastCtrl.create({
              message: res.json().data,
              duration: 3000,
              position: "top",
              color: "success"
            });
            await toast.present();
          }
        }
      }
    }
  }

  onFileSelectedExcel(event) {
    this.imageLink = <File>event.target.files[0];
  }

  async cloudinaryUpload() {
    this.myWidget.open();
  }
}
